<template>
<div class="task-detail">
  <div style="margin: 20px 0 0 20px">
    <MyBreadcrumb></MyBreadcrumb>
  </div>

  <div class="layout-content" >
    <div v-if="!loading">
      <Row>
        <i-col span="2">
          <img v-if="taskDeatil.project.category" :src="constant.STATIC_URL + taskDeatil.project.category.thumbnail" style="width: 70%;"/>
        </i-col>
        <i-col span="22" v-if="taskDeatil.project.category" :push="taskDeatil.project.category.thumbnail ? 0 : 2">
          <Row>
            <i-col span="24" class="project-detail-info">
              <div class="info-item" >
                <strong style="font-size:22px; max-width: 600px; display: inline-block;padding-left: 10px;">{{taskDeatil.project.name}}</strong>
                <!-- <div class="tit-tag" v-if="taskDeatil.step.type" type="primary" @click="toTaskPerform" style="margin-left: 10px; vertical-align: top;" >{{stepTypes[taskDeatil.step.type]}}</div> -->
              </div>
            </i-col>
          </Row>
          <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
            <i-col span="6">项目ID :   {{taskDeatil.project.id}}</i-col>
            <i-col span="6">工序 :  {{taskDeatil.step.name}}({{taskDeatil.step.id}})</i-col>
            <i-col span="6">批次 :   {{taskDeatil.batch.name}}({{taskDeatil.batch.id}})</i-col>
            <i-col span="6">任务ID :   {{taskId}}</i-col>
          </Row>
          <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
            <i-col span="6">项目类型 :   {{taskDeatil.project.category.name}}</i-col>
            <i-col span="6">项目状态 :   {{projectStatus[taskDeatil.project.status]}}</i-col>
            <i-col span="6">作业量 :   {{taskDeatil.batch.amount}}</i-col>
            <i-col span="6">参与人数 :   {{taskDeatil.user_count}}</i-col>
          </Row>
          <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
            <i-col span="6">{{$t('project_starting_time')}} :   {{taskDeatil.start_time}}</i-col>
            <i-col span="6">{{$t('project_finish_time')}} :   {{taskDeatil.end_time}}</i-col>
            <i-col span="6">
              <div class="progress">
                <span>进度 :</span>
                <Tooltip style="width: 100%" :content=" (taskDeatil.stat.work_count ? taskDeatil.stat.work_count : '0') + ' / ' + (taskDeatil.stat.amount ? taskDeatil.stat.amount : 0) + ' / ' + (taskDeatil.batch.amount ? taskDeatil.batch.amount : 0)">
                  <progressOp :row="taskDeatil"></progressOp>
                </Tooltip>
              </div>
            </i-col>
          </Row>
        </i-col>
      </Row>
    </div>
    <Spin fix v-else></Spin>
  </div>
  <div class="cont">
    <div class="cont-top">
      <div class="tab">
        <div :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
      </div>
    </div>
    <div class="cont-item">
      <component :is="curTab.componentName" :stepType="taskDeatil.step && taskDeatil.step.type" :projectId="taskDeatil.project && taskDeatil.project.id"></component>
    </div>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import progressOp from './components/tasks/progress.vue';
import WorkList from './components/detail/workList.vue';
// import WorkStat from './components/detail/workStat.vue';
import statList from '@/views/production/stat/list';
export default {
  name: "taskDetail",
  data(){
    return{
      taskDeatil:{},
      taskId:'',
      loading:false,
      stepTypes:{}, //工序状态
      projectStatus:{}, //项目状态
      tabList:[
        {
          name:'我的作业',
          id:1,
          componentName:'WorkList',
          tabName:'work-list'
        },
        {
          name:'我的绩效',
          id:2,
          componentName:'statList',
          tabName:'stat-stat'
        }
      ],
      curTab:{
        name:'我的作业',
        id:1,
        componentName:'WorkList',
        tabName:'work-list'
      },
    }
  },
  components:{
    progressOp,
    // WorkStat,
    WorkList,
    statList
  },
  created(){
    this.taskId = this.$route.params.id;
    let tabName = this.$route.params.tab;
    this.curTab = this.tabList.filter((item)=>{
      return item.tabName == tabName;
    })[0];

    this.getDetail()
  },
  methods:{
    getDetail(){
      let params = {
        task_id:this.taskId
      };
      this.loading = true;
      this.api.task.taskDetail(params).then((res)=>{
        this.loading = false;
        this.taskDeatil = res.info;

        this.taskDeatil.batch.name = util.decrypt(this.taskDeatil.batch.name);

        this.taskDeatil.start_time = util.timeFormatter(
            new Date(+res.info.start_time * 1000),
            'yyyy-MM-dd'
        );
        this.taskDeatil.end_time = util.timeFormatter(
            new Date(+res.info.end_time * 1000),
            'yyyy-MM-dd'
        );
        this.stepTypes = res.step_types;
        this.projectStatus = res.projectStatuses;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeTab(data){
      if(this.curTab.tabName == data.tabName) return;
      this.curTab = data;
      if(data.id == 1){
        this.$router.push({
          name: 'task-detail',
          params: {
            id: this.$route.params.id,
            tab: this.curTab.tabName,
            subtab: '0'
          }
        });
      }else{
        this.$router.push({
          name: 'task-detail',
          params: {
            id: this.$route.params.id,
            tab: this.curTab.tabName,
            subtab: '0'
          }
        });
      }

    },
    toTaskPerform () {
      this.$router.push({
        name: "task-execute",
        query: {
          project_id: this.taskDeatil.project.id,
          task_id: this.taskId
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
.task-detail{
  .layout-content{
    margin: 20px;
    padding: 20px;
    background: #ffffff;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    position: relative;

    .project-detail-info {
      display: flex;
      justify-content: space-between;
    }
    .project-detail-info .info-item button {
      margin-bottom: 5px;
    }
    .project-detail-info .info-item button + button {
      margin-left: 5px;
    }
    .info-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tit-tag{
        padding: 5px 10px;
        text-align: center;
        color:#FFFFFF;
        border-radius: 4px;
        cursor: pointer;
        background-color:#2d8cf0 ;
      }
    }
    .progress{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >span{
        width: 60px;
      }
    }
  }
  .cont{
    margin: 0 20px 20px 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    .cont-top{
      display: flex;

    }
    .tab{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      border-radius: 4px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;
      line-height: 46px;

      >div{
        width: 100px;
        border-right:1px solid #dcdee2;
        cursor: pointer;
        height: 100%;
      }
      >div:last-child{
        border-right:none;
      }
      >div.active{
        background-color: #2d8cf0;
        color:#FFFFFF;
      }
    }
  }
}
</style>
